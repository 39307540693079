import { makeStyles } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { Markdown } from '@/components/common/Markdown'
import { PageContainer } from '@/components/common/PageContainer'
import { PageCover } from '@/components/common/PageCover'
import { PageTitle } from '@/components/common/PageTitle'
import { SEO } from '@/components/SEO'

const query = graphql`
  query {
    coverImage: strapiCoverImages(slug: { eq: "faith-statement" }) {
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    faithStatement: strapiFaithStatement {
      content
    }
  }
`

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  text: {
    '& h6': {
      margin: spacing(4, 0),
      fontSize: '1.25rem',
      [breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    '& ul': {
      padding: spacing(0, 0, 0, 5),
      listStyleType: 'square',
      [breakpoints.down('sm')]: {
        padding: spacing(0, 0, 0, 3),
      },
    },
    '& ul ul': {
      listStyleType: 'none',
    },
    '& blockquote': {
      marginLeft: spacing(3),
      [breakpoints.down('sm')]: {
        marginLeft: spacing(1.5),
      },
    },
  },
}))

const FaithStatementPage: FC = () => {
  const classes = useStyles()
  const data = useStaticQuery(query)
  const image = getImage(data.coverImage.cover.localFile)

  return (
    <>
      <SEO title="Faith Statement" />
      <PageCover title="Faith Statement" image={image} />
      <PageContainer>
        <PageTitle>Our Basic Beliefs</PageTitle>
        <Markdown className={classes.text}>
          {data.faithStatement.content}
        </Markdown>
      </PageContainer>
    </>
  )
}

export default FaithStatementPage
